import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Redirect, Route, Switch } from "react-router-dom";
// import { ROUTES } from "../../config/routes";
// import '../../styles/app.scss';
// import '../../styles/less/antd.less';
// import Banner from "../../components/Banner";
// import Footer from "../../components/Footer";
import LinkBox from "../../components/LinkBox";
// import Sidebar from "../../components/Sidebar";
// import { BrowserRouter, withRouter } from "react-router-dom";
// import { ROUTE_PATH } from "../../config/general";

class Main extends Component {

    componentDidMount() {
        document.body.classList.add("page-home");
    }
    
	render() {
		return (
			<div id="site-links" className="">
                <div className="logo">
                    <img alt="sdkasd" width='120px' height='120px' src='/assets/logobranco.png' />
                </div>
                <a className="instagram" href="https://www.instagram.com/thaisferristore/">
                    <p>@thaisferristore</p>
                </a>
                <div className="links-box">
                    <LinkBox link="https://api.whatsapp.com/send/?phone=5543991792882" text="WhatsApp (Compras Online)" icon="" />
                    <LinkBox link="https://www.instagram.com/thaisferristore/" text="Instagram" icon="" />
                    <LinkBox link="https://www.facebook.com/profile.php?id=100094124852608" text="Facebook" icon="" />
                </div>
                <footer>
                    <span>Nosso site está em construção!</span>
                </footer>
            </div>
		)
	}
}


export default Main;