import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Redirect, Route, Switch } from "react-router-dom";
// import { ROUTES } from "../../config/routes";
// import '../../styles/app.scss';
// import '../../styles/less/antd.less';
// import Banner from "../../components/Banner";
// import Footer from "../../components/Footer";
import SectionCard from "../../components/SectionCard";
// import Sidebar from "../../components/Sidebar";
// import { BrowserRouter, withRouter } from "react-router-dom";
// import { ROUTE_PATH } from "../../config/general";

class Main extends Component {

    componentDidMount() {
        document.body.classList.add("page-home");
    }
    
	render() {
		return (
            <>
                <div id="site-main" className="container">
                    <div className="sections">
                        <SectionCard text={"NOVIDADES"} image={"/assets/news-banner.jpg"} />
                        <SectionCard text={"VESTIDOS"} image={"/assets/dresses-banner.jpg"} />
                        <SectionCard text={"CONJUNTOS"} image={"/assets/sets-banner.jpg"} />
                        <SectionCard text={"CIMA"} image={"/assets/top-banner.jpg"} />
                        {/* <SectionCard text={"BAIXO"} image={"/assets/back-banner.jpg"} /> */}
                    </div>
                </div>
            </>
		)
	}
}


export default Main;