import React, { Fragment } from 'react';
import ReactDOM from 'react-dom/client';
import './styles/app.scss';
import Main from './screens/Main';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTE_PATH } from "./config/general";
import { ROUTES } from "../src/config/routes";
import Header from './components/Header';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter basename={ROUTE_PATH}>
      <Routes>
        {ROUTES.map((route: any, i: any) => (
          <Route
            key={i}
            path={route.path}
            Component={(props: any) => {

              return (
                <Fragment>
                  <Header />
                  <route.component {...props} />
                </Fragment>
              )
            }}
          />
        ))}
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
