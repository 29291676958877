import { ShoppingCartOutlined } from '@ant-design/icons'
import { Button, Drawer, Empty } from 'antd'
import React, { Component, Fragment, useState } from 'react'

type HeaderState = {
    open: boolean,
};

type HeaderProps = {

};

class Header extends React.Component<HeaderProps, HeaderState> {
    state: HeaderState = {
        open: false
    };

    render() {

        const { open } = this.state;

        const showDrawer = () => {
            this.setState({
                open: true
            })
        };

        const onClose = () => {
            this.setState({
                open: false
            })
        };

        return (
            <header id="site-header">
                <div className="header-container">
                    <div className="logo">
                        <img alt="logo-thais-ferri" width='120px' height='120px' src='/assets/logobranco.png' />
                    </div>
                    <div className="cart">
                        <Button size="large" shape='circle' type='text' onClick={showDrawer}>
                            <ShoppingCartOutlined className='icon' style={{ fontSize: 40 }} />
                        </Button>
                    </div>
                </div>
                <Drawer width={400} className="cart-drawer" onClose={onClose} placement="right" open={open}>
                    <div>
                        <h2>Seu carrinho</h2>
                        {
                            false ? (
                                <Fragment>
                                    <p className="subtitle">Esses são os produtos que você adicionou ao seu carrinho até o momento:</p>
                                    {/* <ul className="product-list">
                                        {
                                            this.props.products.map((product, index) => (
                                                <Product key={product.id} product={product} />
                                            ))
                                        }
                                    </ul> */}
                                </Fragment>
                            ) : (
                                <Empty
                                    style={{
                                        padding: "100px 20px",
                                    }}
                                    description={
                                        <span>
                                            Nenhum item no carrinho, adicione um produto e ele aparecerá aqui!
                                        </span>
                                    }
                                />
                            )
                        }
                    </div>
                    <div className="buy-container">
                        <div className="total-container">
                            <p><strong>Total:</strong> 00,00</p>
                        </div>
                        <p>Ao comprar em nossa loja você concorda com os <span onClick={() => {}}>termos de uso</span> e política de reembolso.</p>
                        <Button block type="primary" disabled={true} onClick={() => {}}>Finalizar compra</Button>
                    </div>
                </Drawer>
            </header>
        )
    }
}

export default Header
