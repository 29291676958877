import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Redirect, Route, Switch } from "react-router-dom";
// import { ROUTES } from "../../config/routes";
// import '../../styles/app.scss';
// import '../../styles/less/antd.less';
// import Banner from "../../components/Banner";
// import Footer from "../../components/Footer";
import ProductCard from "../../components/ProductCard";
import { Drawer } from "antd";
// import Sidebar from "../../components/Sidebar";
// import { BrowserRouter, withRouter } from "react-router-dom";
// import { ROUTE_PATH } from "../../config/general";

type SectionStates = {
    open: boolean;
    products: Product[];
    selected_product: Product | null;
}

type Product = {
    title: string,
    price: number,
    sizes: string[],
    image: string,
    installments: number,
}

class Section extends Component {

    state: SectionStates = {
        open: false,
        products: [
            {
                title: "Blusão Suéter Em Chenille Listrado Com Ribanas Preto/Branco",
                price: 23.23,
                sizes: [],
                image: "/assets/news-banner.jpg",
                installments: 3,
            },
        ],
        selected_product: null
    }
    componentDidMount() {
        document.body.classList.add("page-home");
    }

    render() {
        const { open, products, selected_product } = this.state;

        const showDrawer = (product: Product) => {
            console.log("product: ", product);
            this.setState({
                open: true,
                selected_product: product,
            })
        };

        const onClose = () => {
            this.setState({
                open: false
            })
        };

        return (
            
            <>
                <div id="site-section" className="container">
                    <div className="products">
                        {products.map((product) => (
                            <div className="" onClick={() => showDrawer(product)}>
                                <ProductCard image={product.image} text={product.title} sizes={product.sizes} installments={product.installments} price={product.price} />
                            </div>

                        ))}
                    </div>
                </div>
                <Drawer title={selected_product?.title} placement="right" onClose={onClose} open={open}>
                    <p>Some contents...</p> 
                    <p>Some contents...</p>
                    <p>Some contents...</p>
                </Drawer>
            </>
        )
    }
}


export default Section;