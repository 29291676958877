import { Component } from "react";
// import { Link } from "react-router-dom";
// import { AiOutlineHome } from "react-icons/ai";
// import { MdOutlineLeaderboard } from "react-icons/md";
// import { AiOutlineShop } from "react-icons/ai";
// import { RiDiscordLine } from "react-icons/ri";
// import { FiHelpCircle } from "react-icons/fi";
// import { CgLogIn } from "react-icons/cg";
// import { withRouter } from "react-router-dom";
// import axios from "axios";

interface SectionCardsProps {
    text: string;
    icon: string;
    link: string;
}

class SectionCard extends Component<SectionCardsProps> {
    // constructor(props: SectionCardsProps) {
    //     super(props);

    // }

    render() {
        const { text, icon, link } = this.props;

        return (
            <a href={link} className="link-box">
                <p>
                    {text ?? "none"}
                </p>
            </a>
        );
    }
}

export default SectionCard;